import React,{useState, useEffect, useRef} from 'react'; 
import { useNavigate } from 'react-router-dom';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { InputText } from "primereact/inputtext";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from "primereact/checkbox";
import './settings.css'
import SettingsData from '../SettingsData/SettingsData';

export default function Settings() {
    const toast = useRef(null);
    const [settingsDataVisible, setSettingsDataVisible] = useState(false);
    const [settingsType, setSettingsType] = useState(null);


    const handleSettingsData = (settings_type) =>{
        setSettingsType(settings_type)
        setSettingsDataVisible(true)
    }

    const handleSettingsDataOnHide =() =>{
        setSettingsType(null)
        setSettingsDataVisible(false)
    }

    return (
        <>
        <Toast ref={toast} />
        <div className='settings-card-container'>
        <div className="card-container flex justify-content-center">
            <Card title="URL" className="md:w-25rem card settings-card" onClick={()=>handleSettingsData("url")}>              
            </Card>
        </div>

        <div className="card-container flex justify-content-center">
            <Card title="First Source" className="md:w-25rem card settings-card" onClick={()=>handleSettingsData("source")}>          
            </Card>
        </div>

        <div className="card-container flex justify-content-center">
            <Card title="First Medium" className="md:w-25rem card settings-card" onClick={()=>handleSettingsData("medium")}>              
            </Card>
        </div>

        <div className="card-container flex justify-content-center">
            <Card title="First Campaign" className="md:w-25rem card settings-card" onClick={()=>handleSettingsData("campaign")}>              
            </Card>
        </div>

        <div className="card-container flex justify-content-center">
            <Card title="First Content" className="md:w-25rem card settings-card" onClick={()=>handleSettingsData("content")}>              
            </Card>
        </div>

        </div>

            <Dialog visible={settingsDataVisible} style={{ minWidth: '80%', height: '100%' }} onHide={handleSettingsDataOnHide}>
                <SettingsData settingsType = {settingsType}/>
            </Dialog>
        

        
        </>
    )
}





