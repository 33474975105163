import "primereact/resources/themes/lara-light-indigo/theme.css";     
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';      
import 'bootstrap/dist/css/bootstrap.min.css';
import React,{useState, useEffect, useRef} from 'react'; 
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import './Shortner.css'
import { Toast } from 'primereact/toast';
import axios from '../../Utils/axios'
import { submitUrl, submitCustomUrl, url_list, submitNewUrl, validate_password } from "../../Utils/Urls";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import Settings from "../Settings/Settings";
import { useParams } from 'react-router-dom';

export default function Shortner() {
    const { userEmail } = useParams();
    const validEmails = ['katy.pacheco@resultsdrivenrei.com', 'grethel.alvir@resultsdrivenrei.com', 'support@resultsdrivenrei.com', 'kris@resultsdrivenrei.com', 'ace@resultsdrivenrei.com', 'arvi@resultsdrivenrei.com', 'byron@teksupport.io', 'jeff@resultsdrivenrei.com', 'crm@teksupport.io', 'katy.pacheco@resultsdrivenrei.com'];
    // Convert both the userEmail and the validEmails to lowercase for comparison
    const normalizedEmail = userEmail?.toLowerCase();
    const isValidEmail = validEmails?.some(email => email.toLowerCase() === normalizedEmail);

    const toast = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    const isShortnerRoute = location.pathname.includes('/url_shortner');
    const [url, setUrl] = useState(null);
    const [urlList, setUrlList] = useState([])
    const [firstName, setFirstName] = useState(null);
    const [phone, setPhone] = useState(null);
    const [firstSource, setFirstSource] = useState(null);
    const [firstMedium, setFirstMedium] = useState(null);
    const [firstCampaign, setFirstCampaign] = useState(null);
    const [firstContent, setFirstContent] = useState(null);
    const [firstTerm, setFirstTerm] = useState(null);
    const [firstSourceList, setFirstSourceList] = useState([]);
    const [firstMediumList, setFirstMediumList] = useState([]);
    const [firstCampaignList, setFirstCampaignList] = useState([]);
    const [firstContentList, setFirstContentList] = useState([]);
    const [fullUrl, setFullUrl] = useState('');
    const [firstNameError, setFirstNameError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [firstSourceError, setFirstSourceError] = useState(false);
    const [firstMediumError, setFirstMediumError] = useState(false);
    const [firstCampaignError, setFirstCampaignError] = useState(false);
    const [firstContentError, setFirstContentError] = useState(false);
    const [firstTermError, setFirstTermError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [urlError, setUrlError] = useState(false);
    const [error, setError] = useState(false);
    const [shortUrlError, setShortUrlError] = useState(false);
    const [isMounted, setIsMounted] = useState(false)
    const [urlVisible, setUrlVisible] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordEyeVisible, setPasswordEyeVisible] = useState(false);
    const [settingsVisible, setSettingsVisible] = useState(false);
    const [newUrl, setNewUrl] = useState(null);
    const [password, setPassword] = useState(null);
    
    const load = ()=> {
        console.log('submitting')
        console.log(fullUrl)
        updateGeneratedUrl();
        handleErrors();
        submitUtm();   
    };

    useEffect(()=>{
       
        axios.get(url_list, {
            headers: {'Content-Type': 'application/json'},
            })
            .then((response) => {
                if (response.status === 200) {
                    setUrlList(response.data.url_list)
                    setFirstSourceList(response.data.source_list)
                    setFirstCampaignList(response.data.campaign_list)
                    setFirstMediumList(response.data.medium_list)
                    setFirstContentList(response.data.content_list)
                    }
                })
            .catch((error)=>{
                setUrlList([])
            })
    
        
    },[url_list, isMounted])

    useEffect(() => {
        updateGeneratedUrl();
      }, [url, firstName, phone, firstSource, firstMedium, firstContent, firstCampaign, firstTerm]);

    const handleErrors = () => {
        if (firstName) {
            setFirstNameError(false);
        } else {
            setFirstNameError(true);
        }
        // if (phone) {
        //     setPhoneError(false);
        // } else {
        //     setPhoneError(true);
        // }
        // if (firstSource) {
        //     setFirstSourceError(false);
        // } else {
        //     setFirstSourceError(true);
        // }
        // if (firstMedium) {
        //     setFirstMediumError(false);
        // } else {
        //     setFirstMediumError(true);
        // }
        // if (firstContent) {
        //     setFirstContentError(false);
        // } else {
        //     setFirstContentError(true);
        // }
        // if (firstCampaign) {
        //     setFirstCampaignError(false);
        // } else {
        //     setFirstCampaignError(true);
        // }
    }

    const submitUtm = () => {
        if (!error){
            setLoading(false);
            const data = {
                long_url: fullUrl,
                base_url: url,
                first_name: firstName,
                phone: phone,
                first_source: firstSource,
                first_medium: firstMedium,
                first_campaign: firstCampaign,
                first_content: firstContent,
                first_term: firstTerm
            }
            console.log(data)
            
            if (isShortnerRoute){
                axios.post(submitUrl, data,{
                    headers: {'Content-Type': 'application/json'},
                })
                .then((response)=>{
                    if(response.status === 201){
                        console.log(response.data)
                        setLoading(false);
                        setIsMounted(!isMounted)
                        toast.current.show({severity:'success', summary: 'Success', detail:'Success', life: 1500});
                        // Delay the window reload by 2 seconds
                        setTimeout(() => {
                            window.location.reload();
                        }, 500);
                    }
                    else{
                        console.log(response.data)
                        setLoading(false);
                        setIsMounted(!isMounted)
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to shorten the Url', life: 3000 });
                    }
                })
                .catch((error)=>{
                    console.log(error.response.data.long_url)
                    if(error.response.data.long_url){
                        // setError(true)
                        setUrlError(true)
                    }
                    else{
                        console.log('valid url')
                        setUrlError(false)
                    }
                    setLoading(false);
                    setIsMounted(!isMounted)
                    toast.current.show({severity:'error', summary: 'Error', detail:'Failed to shorten the Url', life: 3000});
                })
            }
            else{
                axios.post(submitUrl, data,{
                    headers: {'Content-Type': 'application/json'},
                })
                .then((response)=>{
                    if(response.status === 201){
                        console.log(response.data)
                        setLoading(false);
                        setIsMounted(!isMounted)
                        toast.current.show({severity:'success', summary: 'Success', detail:'Success', life: 1500});
                        // Delay the window reload by 2 seconds
                        setTimeout(() => {
                            window.location.reload();
                        }, 1500);
                    }
                    else{
                        console.log(response.data)
                        setLoading(false);
                        setIsMounted(!isMounted)
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to shorten the Url', life: 3000 });
                    }
                })
                .catch((error)=>{
                    setLoading(false);
                    setIsMounted(!isMounted)
                    toast.current.show({severity:'error', summary: 'Error', detail:'Failed to shorten the Url', life: 3000});
                })
            }
                
        }
        else{
            console.log(error)
        }   
    }

    const updateGeneratedUrl = () => {
        if (url != null || firstName!= null || phone != null || firstSource != null || firstMedium != null || firstContent != null || firstCampaign != null || firstTerm != null){
            try{
                const queryParams = new URLSearchParams();
            
                if (firstSource) queryParams.append('utm_source', firstSource);
                if (firstMedium) queryParams.append('utm_medium', firstMedium);
                if (firstCampaign) queryParams.append('utm_campaign', firstCampaign);
                if (firstTerm) queryParams.append('utm_term', firstTerm);
                if (firstContent) queryParams.append('utm_content', firstContent);

                const generated = new URL(url);
                generated.search = queryParams.toString();
                setFullUrl(generated.toString());
                setError(false)
                setUrlError(false)
                // if(firstSource && firstCampaign && firstMedium && firstContent){
                //     setError(false)
                //     setUrlError(false)
                // }
                // else{
                //     setError(true)
                //     setUrlError(false)
                // }
            }
            catch (err){
                setFullUrl('');
                setError(true)
                setUrlError(true)
            }
        }
    
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(fullUrl)
          .then(() => {
            toast.current.show({ severity: 'info', summary: 'Copied', detail: 'URL copied to the clipboard' });
          })
          .catch(err => {
            console.error('Failed to copy URL to clipboard', err);
          });
      };

    const handleStatRedirect = () => {
        navigate('/url_stats');
    }

    const handleUrlOnHide = () =>{
        setNewUrl(null)
        setUrlVisible(false)
    }

    const handlePasswordOnHide = () =>{
        setPassword(null)
        setPasswordVisible(false)
    }

    const handleSettingsOnHide =() =>{
        setSettingsVisible(false)
    }

    function isNotInArray(value, array) {
        return !array.includes(value);
      }

      const handlePasswordSubmit = () =>{
        axios.post(validate_password, password,{
            headers: {'Content-Type': 'application/json'},
        })
        .then((response)=>{
            if(response.status === 200){
                setPassword(null)
                setPasswordVisible(false)
                setSettingsVisible(true)
            }
            else{
                toast.current.show({severity:'error', summary: 'Error', detail:'Incorrect Password', life: 1500}); 
            }
        })
        .catch((error)=>{
            toast.current.show({severity:'error', summary: 'Error', detail:'Incorrect Password', life: 3000});
        })
      }

    const handleUrlSubmit = () =>{
        try {
            new URL(newUrl);
            
            const data = {
                newUrl
            }
            axios.post(submitNewUrl, data,{
                headers: {'Content-Type': 'application/json'},
            })
            .then((response)=>{
                if(response.status === 201){
                    setIsMounted(!isMounted)
                    setNewUrl(null)
                    setUrlVisible(false)
                    toast.current.show({severity:'success', summary: 'Success', detail:'New URL added', life: 1500}); 
                }
                else if(response.status === 207){
                    toast.current.show({severity:'error', summary: 'Error', detail:'This URL is already added', life: 1500}); 
                }
                else{
                    console.log(response.data)
                    toast.current.show({severity:'error', summary: 'Error', detail:'Failed to add new URL', life: 1500}); 
                }
            })
            .catch((error)=>{
                toast.current.show({severity:'error', summary: 'Error', detail:'Failed to add new URL', life: 3000});
            })
        }
          catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Invalid URL', life: 3000 });
          }
    }

    const urlAddFooter = (
        <div>
            <Button className='stats-button' label="Submit" icon="pi pi-check" onClick={handleUrlSubmit} autoFocus />
        </div>
    );

    const passwordFooter = (
        <div>
            <Button className='stats-button' label="Submit" icon="pi pi-check" onClick={handlePasswordSubmit} autoFocus />
        </div>
    );

    const handleAddUrl = () => {
        setUrlVisible(true)
    }

    const handlePassword = () => {
        setPasswordVisible(true)
    }

    const handlePasswordVisible = () =>{
        setPasswordEyeVisible(!passwordEyeVisible)
    }

    if (isShortnerRoute && !isValidEmail) {
        return null; // Render nothing if the email is not in the list
      }

    return (
        <div className="shortner-form-container">
            <Toast ref={toast} />
            <div className="shortner-form">
                <div className="form-header mb-3">
                    <h3>UTM Builder</h3>
                    {isShortnerRoute ? (
                    // <Button className="stats-button" label="Stats" onClick={handleStatRedirect} />
                    <span></span>
                    ):
                    (
                    <div>
                        <Button className="stats-button" icon="pi pi-cog" label="Settings" onClick={handlePassword} />
                        {/* <Button className="stats-button" icon="pi pi-plus" label="Add Url" onClick={handleAddUrl} /> */}
                    </div>
                    )}
                </div>
                <div className={`card d-flex flex-row align-items-center justify-content-center border-0 gap-3 mb-3 border-top margin-bottom`}>
                    <div className="row align-items-center" style={{width:'100%'}}>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                            <p className="input-text-lable m-0 mt-3">URL *</p>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{paddingLeft:'1rem', paddingRight:'0px'}}>
                        {isShortnerRoute ? (
                            <InputText className={`input-text mt-3 ${urlError ? 'p-invalid' : ''}`} placeholder="URL" value={url} onChange={(e) => setUrl(e.target.value)}/>
                            ) : (
                            <Dropdown value={url} onChange={(e) => setUrl(e.value)} options={urlList}
                                filter placeholder="Select a URL" className={`input-text mt-3`} />
                            )}
                        </div>
                        {isShortnerRoute && (
                            <>
                            <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                            </div>
                            <div className="col-8 col-md-9 col-lg-11" style={{padding:'0px'}}>
                                <p className={`input-text-error m-0 mt-3 ${urlError ? '' : 'input-text-error-none'}`}>Please enter a valid URL</p>
                            </div>
                            </>
                        )}
                    </div>
                </div>
                {/* <div className="card d-flex flex-row align-items-center justify-content-center border-0 gap-3 mb-3">
                    <div className="row align-items-center" style={{width:'100%'}}>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                            <p className="input-text-lable m-0 mt-3">Location Id</p>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{paddingLeft:'1rem', paddingRight:'0px'}}>
                            <InputText className={`input-text mt-3 ${locationIdError ? 'p-invalid' : ''}`} value={locationId} onChange={(e) => setLocationId(e.target.value)} onBlur={updateGeneratedUrl}/>
                        </div>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{padding:'0px'}}>
                            <p className={`input-text-error m-0 mt-3 ${locationIdError ? '' : 'input-text-error-none'}`}>Please enter the location id</p>
                        </div>
                    </div>
                </div> */}
                <div className={`card d-flex flex-row align-items-center justify-content-center border-0 gap-3 mb-3 ${firstNameError ? '' : 'margin-bottom'}`}>
                    <div className="row align-items-center" style={{width:'100%'}}>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                            <p className="input-text-lable m-0">Creator *</p>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{paddingLeft:'1rem', paddingRight:'0px'}}>
                            <InputText className="input-text" value={firstName} placeholder="Creator" onChange={(e) => setFirstName(e.target.value)}/>
                        </div>

                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{padding:'0px'}}>
                            <p className={`input-text-error m-0 mt-3 ${firstNameError ? '' : 'input-text-error-none'}`}>Please enter the First Name</p>
                        </div>
                    </div>
                </div>
                {/* <div className={`card d-flex flex-row align-items-center justify-content-center border-0 gap-3 mb-3 ${phoneError ? '' : 'margin-bottom'}`}>
                    <div className="row align-items-center" style={{width:'100%'}}>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                            <p className="input-text-lable m-0">Phone *</p>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{paddingLeft:'1rem', paddingRight:'0px'}}>
                            <InputText className="input-text" value={phone} placeholder="Phone: (url link nofitication)" onChange={(e) => setPhone(e.target.value)}/>
                        </div>

                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{padding:'0px'}}>
                            <p className={`input-text-error m-0 mt-3 ${phoneError ? '' : 'input-text-error-none'}`}>Please enter the Phone</p>
                        </div>
                    </div>       
                </div> */}
                <div className={`card d-flex flex-row align-items-center justify-content-center border-0 gap-3 mb-3 ${firstSourceError ? '' : 'margin-bottom'}`}>
                    <div className="row align-items-center" style={{width:'100%'}}>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                            <p className="input-text-lable m-0">First Source</p>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{paddingLeft:'1rem', paddingRight:'0px'}}>
                            {isShortnerRoute ? (
                                <InputText className="input-text" value={firstSource} placeholder="(Organic Social, Organic, Paid Social, Paid, Referral, Organic Search, TV)" onChange={(e) => setFirstSource(e.target.value)}/>
                                ) : (
                                <Dropdown value={firstSource} onChange={(e) => setFirstSource(e.value)} options={firstSourceList}
                                    filter placeholder="(organic, paid)" className={`input-text mt-3`} />
                            )}
                        </div>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{padding:'0px'}}>
                            <p className={`input-text-error m-0 mt-3 ${firstSourceError ? '' : 'input-text-error-none'}`}>Please enter the First Source</p>
                        </div>
                    </div>                 
                </div>
                <div className={`card d-flex flex-row align-items-center justify-content-center border-0 gap-3 mb-3 ${firstMediumError ? '' : 'margin-bottom'}`}>
                    <div className="row align-items-center" style={{width:'100%'}}>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                            <p className="input-text-lable m-0">First Medium</p>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{paddingLeft:'1rem', paddingRight:'0px'}}>
                            {isShortnerRoute ? (
                                    <InputText className="input-text" value={firstMedium} placeholder="(FB, IG, google ads, Search, Email, Referral, Microsoft Ads, Twitter, Tik Tok)" onChange={(e) => setFirstMedium(e.target.value)}/>
                                    ) : (
                                    <Dropdown value={firstMedium} onChange={(e) => setFirstMedium(e.value)} options={firstMediumList}
                                        filter placeholder="(ig, fb, search, email)" className={`input-text mt-3`} />
                                )}
                        </div>

                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{padding:'0px'}}>
                            <p className={`input-text-error m-0 mt-3 ${firstMediumError ? '' : 'input-text-error-none'}`}>Please enter the First Medium</p>
                        </div>
                    </div>
                </div>
                <div className={`card d-flex flex-row align-items-center justify-content-center border-0 gap-3 mb-3 ${firstCampaignError ? '' : 'margin-bottom'}`}>
                    <div className="row align-items-center" style={{width:'100%'}}>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                            <p className="input-text-lable m-0">First Campaign</p>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{paddingLeft:'1rem', paddingRight:'0px'}}>
                            {isShortnerRoute ? (
                                    <InputText className="input-text" value={firstCampaign} placeholder="(Webinar, Post, Page, Story, Reel, Targeting, Bio)" onChange={(e) => setFirstCampaign(e.target.value)}/>
                                    ) : (
                                    <Dropdown value={firstCampaign} onChange={(e) => setFirstCampaign(e.value)} options={firstCampaignList}
                                        filter placeholder="(webinar, reel, post, story)" className={`input-text mt-3`} />
                            )}
                        </div>

                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{padding:'0px'}}>
                            <p className={`input-text-error m-0 mt-3 ${firstCampaignError ? '' : 'input-text-error-none'}`}>Please enter the First Campaign</p>
                        </div>
                    </div>   
                </div>

                <div className={`card d-flex flex-row align-items-center justify-content-center border-0 gap-3 mb-3 ${firstTermError ? '' : 'margin-bottom'}`}>
                    <div className="row align-items-center" style={{width:'100%'}}>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                            <p className="input-text-lable m-0">First Term</p>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{paddingLeft:'1rem', paddingRight:'0px'}}>
                            {isShortnerRoute ? (
                                    <InputText className="input-text" value={firstTerm} placeholder="(Tiff, Josh, RD, Samsing)" onChange={(e) => setFirstTerm(e.target.value)}/>
                                    ) : (
                                    <span></span>
                            )}
                        </div>

                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{padding:'0px'}}>
                            <p className={`input-text-error m-0 mt-3 ${firstTermError ? '' : 'input-text-error-none'}`}>Please enter the First Term</p>
                        </div>
                    </div>              
                </div>

                <div className={`card d-flex flex-row align-items-center justify-content-center border-0 gap-3 mb-3 ${firstContentError ? '' : 'margin-bottom'}`}>
                    <div className="row align-items-center" style={{width:'100%'}}>
                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                            <p className="input-text-lable m-0">First Content</p>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{paddingLeft:'1rem', paddingRight:'0px'}}>
                            {isShortnerRoute ? (
                                    <InputText className="input-text" value={firstContent} placeholder="(market update, itk, vg)" onChange={(e) => setFirstContent(e.target.value)}/>
                                    ) : (
                                    <Dropdown value={firstContent} onChange={(e) => setFirstContent(e.value)} options={firstContentList}
                                        filter placeholder="(tiffany, josh)" className={`input-text mt-3`} />
                            )}
                        </div>

                        <div className="col-4 col-md-3 col-lg-1" style={{padding:'0px'}}>
                        </div>
                        <div className="col-8 col-md-9 col-lg-11" style={{padding:'0px'}}>
                            <p className={`input-text-error m-0 mt-3 ${firstContentError ? '' : 'input-text-error-none'}`}>Please enter the First Content</p>
                        </div>
                    </div>              
                </div>
                
                <div className="form-footer card d-flex flex-row align-items-center justify-content-center border-0 gap-3">
                    <div className="row align-items-center" style={{width:'100%'}}>
                        <div className="col-7 col-md-8 col-lg-10" style={{padding:'1rem'}}>
                        <span className="p-input-icon-right full-width">
                            <i className="pi pi-copy copy-icon" style={{ fontSize: '1.5rem' }}  onClick={copyToClipboard}/>
                            <InputText className="full-url-text" value={fullUrl}/>
                        </span>
                        </div>
                        
                        <div className="col-5 col-md-4 col-lg-2" style={{padding:'1rem'}}>
                            <Button className="shorten-button" label="Generate Short URL" onClick={load} />
                        </div>
                    </div>    
                </div>

                <Dialog header="Add New URL" visible={urlVisible} style={{ minWidth: '50vw' }} onHide={handleUrlOnHide} footer={urlAddFooter}>
                    <div>
                        <div className='purchase-form-group'>
                        <div className="card flex justify-content-center">
                            <InputText type="text" className="p-inputtext-lg language-multiselect" placeholder="Url" value={newUrl} onChange={(e) => setNewUrl(e.target.value)}/>
                        </div>
                        </div>
                    </div>
                </Dialog>

                <Dialog header="Enter the password" visible={passwordVisible} style={{ minWidth: '50vw' }} onHide={handlePasswordOnHide} footer={passwordFooter}>
                    <div>
                        <div className='purchase-form-group'>
                        <div className="card flex justify-content-center">
                            <span className="p-input-icon-right full-input">
                                <i style={{cursor:'pointer'}} className={`pi ${passwordEyeVisible ? 'pi-eye' : 'pi-eye-slash'} password-icon`} onClick={handlePasswordVisible}/>
                                <InputText value={password}  type={`${passwordEyeVisible ? 'text' : 'password'}`} style={{width: '100%'}} className='full-input' id="password" aria-describedby="password-help" onChange={(e) => setPassword(e.target.value)}/>
                            </span>
                        </div>
                        </div>
                    </div>
                </Dialog>

                <Dialog header="Settings" visible={settingsVisible} style={{ minWidth: '50vw' }} onHide={handleSettingsOnHide}>
                    <Settings/>
                </Dialog>

            </div>
        </div>
    )
}





