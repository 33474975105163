// export const baseUrl = "http://127.0.0.1:8000";
export const baseUrl = "https://tekurl.io";
export const submitUrl = "/api/shortener";
export const all_short_url = "/api/all_short_url";
export const all_stats = "/api/all_stats";
export const ValidationUrl = "/api/validation"
export const submitCustomUrl = "/api/custom_shortener";
export const all_custom_stats = "/api/all_custom_stats";
export const url_list = "/api/url_list";
export const submitNewUrl = "api/submitNewUrl";
export const url_track_details = "api/url_track_details";
export const validate_password = "api/validate_password";
export const get_settings_data = "api/get_settings_data";
export const submitNewValue = "api/submitNewValue";
export const submitEditValue = "api/submitEditValue";
export const deleteValue = "api/deleteValue"

