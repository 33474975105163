import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import axios from '../../Utils/axios'
import { get_settings_data, submitNewValue, submitEditValue, deleteValue } from "../../Utils/Urls";
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import './settingsData.css'

export default function SettingsData({settingsType}) {

    const toast = useRef(null);
    const [settingsData, setSettingsData] = useState(null);
    const [isMounted, setIsMounted] = useState(false)
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        value: { value: null, matchMode: FilterMatchMode.STARTS_WITH }
    });
    const [loading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [newValue, setNewValue] = useState(null);
    const [editValueId, setEditValueId] = useState(null);
    const [editValue, setEditValue] = useState(null);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [editDialogVisible, setEditDialogVisible] = useState(false);


    useEffect(()=>{
            console.log(settingsType)
            if (settingsType != null){
                axios.post(get_settings_data, settingsType, {
                    headers: {'Content-Type': 'application/json'},
                })
                    .then((response) => {
                        if (response.status === 200) {
                            setSettingsData(response.data);
                            setLoading(false);
                        }
                        })
                    .catch((error)=>{
                        setSettingsData(null);
                        setLoading(true); 
                    })
            }
            else{
                setSettingsData(null);
                setLoading(true); 
            }
        
    },[isMounted, get_settings_data, settingsType])

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="settings-data-header">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText className='Global-search' value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Global Search" />
                </span>

                <Button className="stats-button add-button" icon="pi pi-plus" label="Add" onClick={() => setDialogVisible(true)}/>
            </div>
        );
    };

    const srNoBodyTemplate = (rowData, props) => {
        const rowIndex = props.rowIndex + 1;
        return <div className='status'>{rowIndex}</div>;
      };

    const editTemplate = (rowData) => {
        const value_id = rowData.id
        return <Button icon="pi pi-pencil" severity="secondary" aria-label="Edit" onClick={() => {setEditDialogVisible(true); setEditValue(rowData.value); setEditValueId(rowData.id)}}/>;
    };

    const deleteTemplate = (rowData) => {
        const value_id = rowData.id
        return <Button icon="pi pi-trash" severity="danger" aria-label="Delete" onClick={()=>handleDeleteValue(value_id)}/>;
    };

    const accept = (Data) => {
        axios.post(deleteValue, Data,{
        headers: {'Content-Type': 'application/json'},
        })
        .then((response) => {
            if (response.status === 200) {
                setIsMounted(!isMounted);
                toast.current.show({ severity: 'success', summary: 'Success', detail: '', life: 3000 });
                }
            else{
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });   
            }
            })
        .catch((error)=>{
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });   
        })
    }

    const reject = () => {
        console.log('closed')
    }

    const handleDeleteValue = (value_id) => {
        const data = {
            settingsType,
            value_id
        }
        
        confirmDialog({
            message: 'Are you sure you want to delete this?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => accept(data),
            reject
        });
    }


    const handleValueSubmit = () =>{
        if (settingsType === 'url'){
            try {
                new URL(newValue);
                
                const data = {
                    settingsType,
                    newValue
                }
                axios.post(submitNewValue, data,{
                    headers: {'Content-Type': 'application/json'},
                })
                .then((response)=>{
                    if(response.status === 201){
                        setIsMounted(!isMounted)
                        setNewValue(null)
                        setDialogVisible(false)
                        toast.current.show({severity:'success', summary: 'Success', detail:'New Value added', life: 1500}); 
                    }
                    else if(response.status === 207){
                        toast.current.show({severity:'error', summary: 'Error', detail:'This value is already added', life: 1500}); 
                    }
                    else{
                        console.log(response.data)
                        toast.current.show({severity:'error', summary: 'Error', detail:'Failed to add new value', life: 1500}); 
                    }
                })
                .catch((error)=>{
                    toast.current.show({severity:'error', summary: 'Error', detail:'Failed to add new value', life: 3000});
                })
            }
              catch (error) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Invalid URL', life: 3000 });
              }
        }
        else{
            const data = {
                    settingsType,
                    newValue
                }
                axios.post(submitNewValue, data,{
                    headers: {'Content-Type': 'application/json'},
                })
                .then((response)=>{
                    if(response.status === 201){
                        setIsMounted(!isMounted)
                        setNewValue(null)
                        setDialogVisible(false)
                        toast.current.show({severity:'success', summary: 'Success', detail:'New Value added', life: 1500}); 
                    }
                    else if(response.status === 207){
                        toast.current.show({severity:'error', summary: 'Error', detail:'This value is already added', life: 1500}); 
                    }
                    else{
                        console.log(response.data)
                        toast.current.show({severity:'error', summary: 'Error', detail:'Failed to add new value', life: 1500}); 
                    }
                })
                .catch((error)=>{
                    toast.current.show({severity:'error', summary: 'Error', detail:'Failed to add new value', life: 3000});
                })
            }
        
    }

    const handleEditValueSubmit = () =>{
        if (settingsType === 'url'){
            try {
                new URL(editValue);
                
                const data = {
                    settingsType,
                    editValueId,
                    editValue
                }
                axios.post(submitEditValue, data,{
                    headers: {'Content-Type': 'application/json'},
                })
                .then((response)=>{
                    if(response.status === 200){
                        setIsMounted(!isMounted)
                        setEditValue(null)
                        setEditDialogVisible(false)
                        toast.current.show({severity:'success', summary: 'Success', detail:'Value edited', life: 1500}); 
                    }
                    else if(response.status === 207){
                        toast.current.show({severity:'error', summary: 'Error', detail:'This value is already added', life: 1500}); 
                    }
                    else{
                        console.log(response.data)
                        toast.current.show({severity:'error', summary: 'Error', detail:'Failed to edit', life: 1500}); 
                    }
                })
                .catch((error)=>{
                    toast.current.show({severity:'error', summary: 'Error', detail:'Failed to edit', life: 3000});
                })
            }
              catch (error) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Invalid URL', life: 3000 });
              }
        }
        else{
            const data = {
                    settingsType,
                    editValueId,
                    editValue
                }
                axios.post(submitEditValue, data,{
                    headers: {'Content-Type': 'application/json'},
                })
                .then((response)=>{
                    if(response.status === 200){
                        setIsMounted(!isMounted)
                        setEditValue(null)
                        setEditDialogVisible(false)
                        toast.current.show({severity:'success', summary: 'Success', detail:'Value edited', life: 1500}); 
                    }
                    else if(response.status === 207){
                        toast.current.show({severity:'error', summary: 'Error', detail:'This value is already added', life: 1500}); 
                    }
                    else{
                        console.log(response.data)
                        toast.current.show({severity:'error', summary: 'Error', detail:'Failed to edit', life: 1500}); 
                    }
                })
                .catch((error)=>{
                    toast.current.show({severity:'error', summary: 'Error', detail:'Failed to edit', life: 3000});
                })
            }
        
    }

    const valueAddFooter = (
        <div>
            <Button className='stats-button' label="Submit" icon="pi pi-check" onClick={handleValueSubmit} autoFocus />
        </div>
    );

    const valueEditFooter = (
        <div>
            <Button className='stats-button' label="Submit" icon="pi pi-check" onClick={handleEditValueSubmit} autoFocus />
        </div>
    );

    const handleDialogOnHide = () =>{
        setNewValue(null)
        setDialogVisible(false)
    }

    const handleEditDialogOnHide = () =>{
        setEditValue(null)
        setEditDialogVisible(false)
    }

    const header = renderHeader();

    return (
        <div>
            <Toast ref={toast} />
            <ConfirmDialog />
            <DataTable value={settingsData} showGridlines paginator rows={6} dataKey='id' filters={filters} loading={loading}
                    globalFilterFields={['value']} header={header} emptyMessage="No Data..">
                <Column header="SR NO" className="padding-0" style={{ width: '1.5rem'  }} body={srNoBodyTemplate} />
                <Column field="value" header="Value" sortable style={{ minWidth: '8rem' }} />
                <Column header="Edit" style={{ width: '10rem' }} body={editTemplate}/>
                <Column header="Delete" style={{ width: '10rem' }} body={deleteTemplate}/>
            </DataTable>

            <Dialog visible={dialogVisible} style={{ minWidth: '50vw' }} onHide={handleDialogOnHide} footer={valueAddFooter}>
                    <div>
                        <div className='purchase-form-group'>
                        <div className="card flex justify-content-center">
                            <InputText type="text" className="p-inputtext-lg language-multiselect" placeholder="Enter new value" value={newValue} onChange={(e) => setNewValue(e.target.value)}/>
                        </div>
                        </div>
                    </div>
            </Dialog>

            <Dialog visible={editDialogVisible} style={{ minWidth: '50vw' }} onHide={handleEditDialogOnHide} footer={valueEditFooter}>
                    <div>
                        <div className='purchase-form-group'>
                        <div className="card flex justify-content-center">
                            <InputText type="text" className="p-inputtext-lg language-multiselect" placeholder="Enter new value" value={editValue} onChange={(e) => setEditValue(e.target.value)}/>
                        </div>
                        </div>
                    </div>
            </Dialog>
        </div>
    );
}




