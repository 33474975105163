import './App.css';
import { BrowserRouter as Router, Routes, Route  } from 'react-router-dom';
import Shortner from './Components/Shortner/Shortner';
import Stat from './Components/StatTable/Stat';
import OnboardForm from './Components/OnboardForm/OnboardForm';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Shortner/>} />
          <Route path="/url_shortner/:userEmail" element={<Shortner/>} />
          <Route path="/stats/:userEmail" element={<Stat/>} />
          <Route path="/url_stats" element={<Stat/>} />
          <Route path="/onboard" element={<OnboardForm/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
